import { SECURE_COOKIE } from "@globalConfig";
import useCookieClient from "@hooks/useCookieClient";
var COOKIES_CONFIGS = {
  path: "/",
  maxAge: 3600 * 24 * 365 * 10,
  sameSite: true,
  secure: SECURE_COOKIE
};
var _useCookieClient = useCookieClient(),
  setCookieClient = _useCookieClient.setCookieClient;
export function cookie__setAccessToken(token) {
  setCookieClient("accessToken", token || '', COOKIES_CONFIGS);
}
export function cookie__setUserId(userId) {
  setCookieClient("userId", userId ? userId : '', COOKIES_CONFIGS);
}